export default function (bem) {
  let window = 'info'
   this.$uloc.window.new({
    wid: window,
    title: 'Ver completo',
    move: false,
    backdrop: true,
    clickOutside: false,
    closeOnEsc: false,
    windowClass: 'erp-window-modern v2 no-border-radius',
    width: '1000',
    height: '680',
    props: {
      bem,
      $router: this.$router,
    }
  }, () => import('../components/Info.vue'))
    .then((wid) => {
      this.$uloc.window.listen(wid, {
        update: (wid, val) => {
          this.load && this.load()
        },
        created: (wid, val) => {
          this.load && this.load()
        }
      })
    }) // return wid
}
